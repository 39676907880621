<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

    .scrollable-table {
      /*max-height: 300px;*/
      overflow-y: auto;
    }

    th, td {
      white-space: nowrap;
    }

    .text-center{
      text-align: center;
    }

</style>
<template>
    <div class="content-wrapper">
       <div class="container-xxl flex-grow-1 container-p-y">

          <div class="row justify-content-between mb-2">
              <div class="col-auto">
                <div class="d-flex align-items-center gap-2">
                  <h4 class="fw-bold mb-1"> Invoice</h4>
                </div>
              </div>
              <div class="col-auto gap-2">
                <div class="d-flex align-items-center gap-2">
                   <button  type="button" v-if="!$store.state.AppConfig.invoiceCSVDownloading && !downloading_complete" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
                     <span class="ti-xs ti ti-download me-1"></span>Download CSV
                   </button>

                   <button v-if="$store.state.AppConfig.invoiceCSVDownloading" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border me-1" role="status" aria-hidden="true"></span>
                    Downloading...
                  </button>

                  <router-link v-if="downloading_complete" @click="resetDownloadStatus" class="btn btn-primary waves-effect" :to="'/files/'+csv_file_name" target="_blank"><span class="ti-xs ti ti-download me-1"></span>Download Ready</router-link>

                   <router-link  class="btn btn-outline-primary waves-effect" to="/download-history">Download History</router-link>
                </div>
              </div>
          </div>

          <div class="card mb-4">
            <h5 class="card-header border-bottom">Filters</h5>
            <VeeForm  ref="filterForm" as="div" class="needs-validation" name="filter" v-slot="{ errors }">
              <form class="card-body">
                <div class="row g-3">
                  <!-- <pre>{{filter}}</pre> -->
                  <div class="col-md-3" >
                    <label class="form-label" for="suppliers"><strong>Invoice Number <span class="required">*</span></strong></label>
                      <Field 
                          id="invoice_number" 
                          type="text" 
                          class="form-control" 
                          rules="required"  
                          name="invoice_number" 
                          v-model="filter.invoice_number" 
                          label="Invoice Number">
                      </Field>
                      <small class="text-danger">{{ errors.invoice_number }}</small>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="countries"><strong>Country</strong></label>
                    <select
                      id="countries"
                      name="countries"
                      class="select2 form-select form-control"
                      v-model="filter.country"
                    >
                      <option value="">--Select--</option>
                      <option v-if="$store.state.AppConfig.countries.length" v-for="country in $store.state.AppConfig.countries" :value="country.Country">{{country.Country}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="suppliers"><strong>Suppliers</strong></label>
                    <select
                      id="suppliers"
                      name="suppliers"
                      class="select2 form-select form-control"
                      v-model="filter.supplier"
                    >
                      <option value="">--Select--</option>
                      <option v-if="suppliers.length" v-for="supplier in suppliers" :value="supplier.SupplierCode">{{supplier.SupplierName}}</option>
                    </select>
                  </div>
                  
                </div>

                <div class="pt-4">
                  <button type="button" @click="submit" class="btn btn-primary me-sm-3 me-1">Apply</button>
                  <router-link  class="btn btn-label-primary waves-effect" to="/dashboard">Cancel</router-link>
                </div>
              </form>
            </VeeForm>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <div class="table-responsive scrollable-table">
                <!-- <table class="table w-100">
                  <thead>
                    <tr>
                      <th><strong>Customer</strong></th>
                      <th><strong>Country</strong></th>
                      <th><strong>Supplier</strong></th>
                      <th><strong>Received Date</strong></th>
                      <th><strong>Invoice Number</strong></th>
                      <th><strong>Invoice_Date</strong></th>
                      <th><strong>Currency</strong></th>
                      <th><strong>NetExpenseAmt</strong></th>
                      <th><strong>GrossExpenseAmt</strong></th>
                      <th><strong>Comments</strong></th>
                      <th><strong>Validated</strong></th>
                      <th><strong>Validated Date</strong></th>
                      <th><strong>Original_Invoice</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-for="invoice in invoiceData" v-if="invoiceData.length>0">
                      <td>{{invoice.Customer}}</td>
                      <td>{{invoice.Country}}</td>
                      <td>{{invoice.Supplier}}</td>
                      <td>{{$filters.dateFormat(invoice.Received_Date)}}</td>
                      <td>
                        <router-link target="_blank" :to="'/tblVehCostAllocation/'+invoice['Invoice Number']">{{invoice['Invoice Number']}}</router-link>
                      </td>
                      <td>{{$filters.dateFormat(invoice.Invoice_Date)}}</td>
                      <td>{{invoice.Currency}}</td>
                      <td>{{$filters.numberFormat(invoice.NetExpenseAmt)}}</td>
                      <td>{{$filters.numberFormat(invoice.GrossExpenseAmt)}}</td>
                      <td>{{invoice.Comments}}</td>
                      <td>
                        <span v-if="invoice.Validated ==1">Validated</span>
                        <span v-else>Not Validated</span>
                      </td>
                      <td>{{$filters.dateFormat(invoice['Validated Date'])}}</td>
                      <td>{{invoice.Original_Invoice}}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="8" class="text-center">No record found.</td>
                    </tr>
                  </tbody>
                </table> -->

                <table class="table w-100">
                  <thead>
                    <tr>
                      <th><strong>Customer</strong></th>
                      <th><strong>Cost_Category</strong></th>
                      <th><strong>RegNumber</strong></th>
                      <th><strong>EmployeeID</strong></th>
                      <th><strong>Invoice Number</strong></th>
                      <th><strong>VehExpenseDate</strong></th>
                      <th><strong>CostCentre</strong></th>
                      <th><strong>Expense Description</strong></th>
                      <th><strong>Currency</strong></th>
                      <th><strong>NetExpenseAmt</strong></th>
                      <th><strong>Supplier Name</strong></th>
                      <th><strong>Comments</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-for="data in invoiceData" v-if="invoiceData.length>0">
                      <td>{{data.Customer}}</td>
                      <td>{{data.Cost_Category}}</td>
                      <td>{{data.RegNumber}}</td>
                      <td>{{data.EmployeeID}}</td>
                      <td>{{data['Invoice Number']}}</td>
                      <td>{{$filters.dateFormat(data.VehExpenseDate)}}</td>
                      <td>{{data.CostCentre}}</td>
                      <td>{{data.ExpenseDescription}}</td>
                      <td>{{data.Currency}}</td>
                      <td>{{$filters.numberFormat(data.NetExpenseAmt)}}</td>
                      <td>{{data.SupplierName}}</td> 
                      <td>{{data.Comments}}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="8" class="text-center">No record found.</td>
                    </tr>
                  </tbody>
                </table>
                <spinner :active="loading" :is-full-page="true"></spinner> 
                <spinner :active="downloading" :is-full-page="true"></spinner> 
              </div>

              <div class="px-2 d-flex justify-content-between py-1">      
                  <div>
                    <div class="flex-items">  
                      <div class="align-items-center  d-flex" style="padding-top:2px">
                        <span>Show </span>&nbsp;
                        <span>
                          <select style="width: fit-content;" class="form-select form-select-sm" v-model="recordsPerPage">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </span>&nbsp;&nbsp;
                        <span>Per Page</span>&nbsp;<span>(Total : {{totalInvoices}})</span>
                      </div>
                    </div>
                  </div> 
                  <div>
                    <Pagination
                      v-if="totalInvoices > recordsPerPage"
                      v-model="currentPage"
                      :activePage="currentPage" 
                      :per-page="recordsPerPage"
                      :total-rows="totalInvoices">   
                    </Pagination>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import Pagination from '../../components/Pagination';

export default {
    name: 'invoice',
    components:{
      'VeeForm': Form,
      Field,
      Pagination,
    },
    props: [],
    data(){
        return{
           invoiceData: [],
           suppliers: [],
           filter: {
              "country":"",
              "supplier":"",
              "invoice_number": "",
           },
           loading: false,

          totalInvoices: 0,
          currentPage:1,
          recordsPerPage:10,
          //downloading: false,
          downloading_complete: false,
          csv_file_name: "",
        }   
    },
    created() {
      var self = this;
      self.getSuppliers();
    },
    mounted() {
    },
    setup() {
    },
    computed:{
        
    },
    watch:{
      'recordsPerPage'(newVal, oldVal){
        var self = this
        if(oldVal && newVal && newVal != oldVal){
          this.loadFirstPage();
        }
      },
      "currentPage"(newVal, oldVal){
        if(oldVal && newVal && newVal != oldVal){
          this.currentPage = newVal;
          this.getInvoiceData();
        }
      },
      
    },
    methods:{
      getSuppliers(){
        var self = this;
        self.$axios.get('/suppliers/all')
          .then(response => {
            if (!response.data.error){                    
                self.suppliers = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      async submit(){
        var self = this;
        const form = this.$refs.filterForm;
        const isValid = await form.validate();

        if (isValid.valid) {
          self.getInvoiceData();
        }
      },

      getInvoiceData(){
          var self = this;
          self.loading = true;

          self.filter.per_page = self.recordsPerPage;
          self.$axios.post('/invoice/'+ self.currentPage, self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.invoiceData = response.data.input_data.data;
                self.totalInvoices = response.data.input_data.total;
                self.loading = false;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      loadFirstPage(){
          var self = this;
          clearTimeout(self.debounceTimeout); // Clear the previous timeout
          self.debounceTimeout = setTimeout(() => {
            self.currentPage = null;
            self.currentPage = 1;
            self.getInvoiceData();
          }, 500);
        },

      downloadCsv() {

        var self = this;
        //self.downloading = true;
        self.$store.commit('SET_INVOICE_CSV_DOWNLOADING', true);
        const queryParams = Object.keys(self.filter)
              .map(key => {
                  if (key === 'start_date' || key === 'end_date') {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(new Date(self.filter[key]).toISOString());
                  } else {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(self.filter[key]);
                  }
              })
              .join('&');

        const apiUrl = '/download-csv/invoice?' + queryParams;

        self.$axios.get(apiUrl)
        .then(response => {
            self.downloading_complete = true;
            self.csv_file_name = response.data.input_data.csv_file_name;

            self.$store.commit('SET_INVOICE_CSV_DOWNLOADING', false);
        })
        .catch(error => {
            console.error('Error downloading CSV', error);
        });
      },

      resetDownloadStatus() {
          this.downloading_complete = false;
      },

    },

}
</script>
