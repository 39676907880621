<style type="text/css">
</style>
<template>
   <div class="container-xxl flex-grow-1 container-p-y">
       <h4 class="fw-bold mb-4">Profile</h4>
        <div class="card">
            <div class="card-body">
                <VeeForm  ref="userForm" as="div" class="needs-validation" name="user" v-slot="{ errors }">
                  <form class="validate-form mt-2 pt-50" method="POST" action="/">
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="formtabs-first-name"><strong>First Name<span class="required">*</span></strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="first_name" 
                                  rules="required"
                                  v-model="user.first_name" 
                                  placeholder="First Name"
                                  label="First Name">
                              </Field>
                              <small class="text-danger">{{ errors.first_name }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-last-name"><strong>Last Name<span class="required">*</span></strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="last_name" 
                                  rules="required"
                                  v-model="user.last_name" 
                                  placeholder="Last Name"
                                  label="Last Name">
                              </Field>
                              <small class="text-danger">{{ errors.last_name }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-mobile"><strong>Mobile<span class="required">*</span></strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="mobile" 
                                  rules="required"
                                  v-model="user.mobile" 
                                  placeholder="Mobile"
                                  label="Mobile">
                              </Field>
                              <small class="text-danger">{{ errors.mobile }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-email"><strong>Email<span class="required">*</span></strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="email" 
                                  rules="required|email"
                                  v-model="user.email" 
                                  placeholder="Email"
                                  label="Email">
                              </Field>
                              <small class="text-danger">{{ errors.email }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-address"><strong>Address<span class="required">*</span></strong></label>
                              <Field as="textarea" 
                                  class="form-control"
                                  rows="4"
                                  name="address" 
                                  rules="required"
                                  v-model="user.address1" 
                                  placeholder="Address"
                                  label="Address">
                              </Field>
                              <small class="text-danger">{{ errors.address }}</small>
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="formtabs-address"><strong>Post Code</strong></label>
                              <Field  type="text" 
                                  class="form-control"
                                  name="address2" 
                                  v-model="user.address2" 
                                  placeholder="Post Code"
                                  label="Post Code">
                              </Field>
                            </div>

                            <div class="mb-3">
                                <user-photo :user="user" ></user-photo>
                            </div>
                        </div>                   
                    </div>
                    
                    <div class="pt-4">
                        <button type="button" @click="submitForm" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light">Submit</button>
                        <router-link  class="btn btn-label-primary waves-effect" to="/users/list">Cancel</router-link>
                      </div>
                  </form>
                </VeeForm>
              
            </div>
        </div>
    </div>
</template>

<script>
import jQuery from 'jquery'
import moment from 'moment';
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '../js/validation';
import UserPhoto from '../components/UserPhotoUpload.vue';

export default {
    name: 'profile',
    props: ['shared'],
    components:{
        'VeeForm': Form,
        Field,
        'v-select': vSelect,
        'user-photo': UserPhoto,
    },
    data(){
        return{
            user: {
               client: null,
               role_name: 'user'
            },
            userId : this.$store.state.AppConfig.loggedin_user.id,
        }   
    },
    created() {
        var self =this;
        self.getUserDetail();
    },

    computed:{
    },

    methods:{
        async submitForm() {
            const form = this.$refs.userForm;
            const isValid = await form.validate();

            if (isValid.valid) {
                if(this.user.id){
                  this.updateUser();
                }
            }
        },

        updateUser(){
          var self = this;
          self.$axios.put('/user', self.user)
            .then(response => {
              if (!response.data.error){                    
                  toastr['success']('User Updated Successfully', 'Success', {
                      closeButton: true,
                      tapToDismiss: false,
                      timeOut: 5000,
                      rtl: false,
                  });
              }
            })
            .catch(error => {
              console.error(error);
            });
        },

        getUserDetail(){
            var self = this;
            self.$axios.get('/user')
              .then(response => {
                if (!response.data.error){                    
                    self.user = response.data.input_data;
                }
              })
              .catch(error => {
                console.error(error);
              });
        },

        updateLogo(newLogo){
          this.user.logo = newLogo;
        },

       
    },

    watch:{
        
    },
}
</script>