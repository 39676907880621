<template>
  <!-- <span v-html="getSVG()" :size="size" :style="style"></span> -->
</template>
<script>
export default {
  name: 'FeatherIcon',
  functional: true,
  props: {
    icon: {
      required: true,
      type: [String, Object],
    },
    size: {
      type: String,
      default: '14',
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: 'badge-primary',
    },
    style: {
      type: String,
      default: '',
    },
  },
  methods:{
    getSVG(){
      // Create Feather Icon
      // const svg = feather.icons[this.icon].toSvg()
      // If no badge is provided => Render just SVG
      // if (!this.badge) return svg;
    }
  }
}
</script>

<style lang="scss">
svg.feather {
  outline: none
}
</style>
