<template>
  <div class="hello">
    <h1>Page not Found......</h1>
  </div>
</template>

<script>
export default {
  name: 'pageNotFound',
  props: {
    msg: String
  },
  created(){
  }
}
</script> 