import moment from 'moment-timezone';
import {Form, Field, defineRule, ErrorMessage,configure,useFormErrors } from 'vee-validate';
import store from '../store/store'
import { localize } from '@vee-validate/i18n';
import Mixins from './Mixins';
import { 
    email,
    required
    } from '@vee-validate/rules';

defineRule('required', required)
defineRule('email', email)


configure({
    // Generates an English message locale generator
    generateMessage: localize('en', {
        messages: {
            required:(ctx) => "The :attribute field is required.".replace(':attribute',ctx.field),
            email:(ctx) => 'The :attribute must be a valid email address.'.replace(':attribute',ctx.field),
        },
    }),
});