const actions = {
    siteConfig({ commit }, payload) {
      commit('UPDATE_SITE_CONFIG', payload)
    },
    updateUser({ commit }, payload) {
      commit('UPDATE_USER', payload)
    },
    countries({ commit }, payload) {
      commit('COUNTRIES', payload)
    },
    suppliers({ commit }, payload) {
      commit('SUPPLIERS', payload)
    },
    customers({ commit }, payload) {
      commit('CUSTOMERS', payload)
    },

}

export default actions
