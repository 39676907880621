<style type="text/css">
    .table th{
        text-transform: none !important;
    }

</style>
<template>
    <div v-if="$store.state.AppConfig.loggedin_user.role_name =='superadmin'">
        <div class="card">
          <h5 class="card-header">Upload Report</h5>
          <div class="card-body">
            <VeeForm  ref="reportForm" as="div" class="needs-validation" name="report" v-slot="{ errors }">
              <form class="validate-form mt-2 pt-50" method="POST" action="/">
                <div class="row g-3">
                    <div class="col-md-6">
                      <!-- <pre>{{report}}</pre> -->
                       <div class="mb-3">
                          <label class="form-label" for="modalEditUserLanguage"><strong>Client<span class="required">*</span></strong></label>
                          <select
                            id="modalEditUserLanguage"
                            name="modalEditUserLanguage"
                            class="select2 form-select form-control"
                            aria-label="Default select example"
                            v-model="report.client"
                          >
                            <option value="">--Select--</option>
                            <option v-if="$store.state.AppConfig.customers.length" v-for="customer in $store.state.AppConfig.customers" :value="customer.CustomerID">{{customer.CompanyName}}</option>
                          </select>
                        </div>

                        <div class="mb-3">
                          <label class="form-label" for="formtabs-last-name"><strong>Report Name<span class="required">*</span></strong></label>
                          <Field  type="text" 
                              class="form-control"
                              name="report_name" 
                              rules="required"
                              v-model="report.report_name" 
                              placeholder="Report Name"
                              label="Report Name">
                          </Field>
                          <small class="text-danger">{{ errors.report_name }}</small>
                        </div>
                    </div>                   
                </div>

                <div class="row g-3">
                    <div class="col-md-6">
                      <FileUpload :data="report" url="/api/service/v1/report/upload"/>
                    </div>                   
                </div>
                
                <div class="pt-4">
                    <button type="button" @click="submitForm" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light">Submit</button>
                    <router-link  class="btn btn-label-primary waves-effect" to="/reports/history">Cancel</router-link>
                  </div>
              </form>
            </VeeForm>
          </div>
        </div>
    </div>
    <div v-else>
      Access denied.
    </div>
</template>

<script>
import jQuery from 'jquery'
import moment from 'moment';
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '../../js/validation';
import FileUpload from "../../components/FileUpload.vue";

export default {
    name: 'reports',
    components:{
        'VeeForm': Form,
        Field,
        'v-select': vSelect,
        FileUpload
    },
    props: [],
    data(){
        return{
            report: {
              client: "",
              report_name: "",
              file_name: ""
            },
        }   
    },

    created() {
    },
    setup() {
    },
    mounted() {
    },

    computed:{
    },

    methods:{

        newReport(){
          var self = this;
          self.report = {
            client: null,
            report_name: "",
            file_name: ""
          }
        },
      
        async submitForm() {
            const form = this.$refs.reportForm;
            const isValid = await form.validate();

            if (isValid.valid) {
              this.storeReport();
            }
        },

        storeReport(){
          var self = this;
          self.$axios.post('/report', self.report)
            .then(response => {
              if (!response.data.error){ 
                 self.$router.push({ path: '/reports/history' });
              }
              else{
                toastr['error'](response.data.input_data.error_message, 'Error', {
                      closeButton: true,
                      tapToDismiss: false,
                      timeOut: 5000,
                      rtl: false,
                  });
              }
            })
            .catch(error => {
              console.error(error);
            });
        },

        updateFilename(filename){
          this.report.file_name = filename;
        }
        
    },

    watch:{
        
    },
}
</script>
