<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

</style>
<template>
    <div class="content-wrapper">
       <div class="container-xxl flex-grow-1 container-p-y">
          <h4 class="fw-bold ">Replacements Due</h4>

          <div class="card mb-4">
            <h5 class="card-header">Filters</h5>
            <form class="card-body">
              <div class="row g-3">
                <div class="col-md-3">
                  <label class="form-label" for="multicol-username"><strong>Client <span class="required">*</span></strong></label>
                    <select id="client" class="select2 form-select" data-allow-clear="true">
                        <option value="">Select</option>
                        <option value="AL">Autodesk</option>
                        <option value="AK">Dell</option>
                        <option value="AZ">Oracle</option>
                    </select>
                </div>
                <div class="col-md-3">
                  <label class="form-label" for="multicol-email"><strong>Country</strong></label>
                    <select id="client" class="select2 form-select" data-allow-clear="true">
                        <option value="">Select</option>
                        <option value="AL">Angola</option>
                        <option value="AK">Belgium</option>
                        <option value="AZ">Togo</option>
                    </select>
                </div>
                <div class="col-md-3">
                  <div class="form-password-toggle">
                    <label class="form-label" for="multicol-password"><strong>Division</strong></label>
                    <select id="client" class="select2 form-select" data-allow-clear="true">
                        <option value="">Select</option>
                        <option value="AL">Crossroads</option>
                        <option value="AK">EMC</option>
                        <option value="AZ">Zoetis</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-password-toggle">
                    <label class="form-label" for="multicol-password"><strong>Suppliers</strong></label>
                    <select id="client" class="select2 form-select" data-allow-clear="true">
                        <option value="">Select</option>
                        <option value="AL">AIG</option>
                        <option value="AK">Aon</option>
                        <option value="AZ">Arval</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-password-toggle">
                    <label class="form-label" for="multicol-confirm-password"><strong>Start Date <span class="required">*</span></strong></label>
                    <div class="input-group input-group-merge">
                      <input
                        type="date"
                        id="multicol-confirm-password"
                        class="form-control"
                        aria-describedby="multicol-confirm-password2"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-password-toggle">
                    <label class="form-label" for="multicol-confirm-password"><strong>End Date<span class="required">*</span></strong></label>
                    <div class="input-group input-group-merge">
                      <input
                        type="date"
                        id="multicol-confirm-password"
                        class="form-control"
                        aria-describedby="multicol-confirm-password2"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="pt-4">
                <button type="submit" class="btn btn-primary me-sm-3 me-1">Search</button>
                <button type="reset" class="btn btn-label-secondary">Cancel</button>
              </div>
            </form>
          </div>

          <div class="card mb-4">
            <form class="card-body">
                <table class="table">
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th>Division</th>
                    <th>Country</th>
                    <th>01/01/2023</th>
                    <th>01/02/2023</th>
                    <th>01/03/2023</th>
                    <th>01/04/2023</th>
                    <th>01/05/2023</th>
                  </tr>
                </thead>
                <tbody class="table-border-bottom-0">
                  <tr>
                    <td>Quest</td>
                    <td>Quest</td>
                    <td>Italy</td>
                    <td>120</td>
                    <td>223</td>
                    <td>223</td>
                    <td>321</td>
                    <td>329</td>
                  </tr>
                  <tr>
                    <td>Quest</td>
                    <td>Quest</td>
                    <td>United Kingdom</td>
                    <td>120</td>
                    <td>123</td>
                    <td>152</td>
                    <td>201</td>
                    <td>230</td>
                  </tr>
                  <tr>
                    <td>Quest</td>
                    <td>Quest</td>
                    <td>Belgium</td>
                    <td>100</td>
                    <td>178</td>
                    <td>178</td>
                    <td>201</td>
                    <td>226</td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>

            <div class="card">
                <div class="card-header header-elements">
                  <!-- <h5 class="card-title mb-0">Average Skills</h5> -->
                    <div class="card-header-elements ms-auto py-0 dropdown">
                      <button
                        type="button"
                        class="btn dropdown-toggle p-0"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="ti ti-calendar"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <a href="javascript:void(0);" class="dropdown-item d-flex align-items-center">Today</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="dropdown-item d-flex align-items-center"
                            >Yesterday</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="dropdown-item d-flex align-items-center"
                            >Last 7 Days</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="dropdown-item d-flex align-items-center"
                            >Last 30 Days</a
                          >
                        </li>
                        <li>
                          <hr class="dropdown-divider" />
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="dropdown-item d-flex align-items-center"
                            >Current Month</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="dropdown-item d-flex align-items-center"
                            >Last Month</a
                          >
                        </li>
                      </ul>
                    </div>
                  <!-- <div class="card-header-elements ms-auto py-0 dropdown">
                    <button
                      type="button"
                      class="btn dropdown-toggle hide-arrow p-0"
                      id="heat-chart-dd"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="ti ti-dots-vertical"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="heat-chart-dd">
                      <a class="dropdown-item" href="javascript:void(0);">Last 28 Days</a>
                      <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                      <a class="dropdown-item" href="javascript:void(0);">Last Year</a>
                    </div>
                  </div> -->
                </div>
                <div class="card-body">
                    <div class="row g-3">
                        <div class="col-md-3">
                            <label class="form-label" for="multicol-confirm-password">Chart By</label>
                            <select id="client" class="select2 form-select" data-allow-clear="true">
                                <option value="">Select Chart By</option>
                                <option value="AL" >Country</option>
                                <option value="AK">Supplier</option>
                            </select>
                        </div>
                        <div class="col-md-9">
                            <canvas ref="polarChart" class="chartjs" data-height="337"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'fleet-size',
    components:{
    },
    props: [],
    data(){
        return{
           myChart: "",
        }   
    },
    created() {
    },
    mounted() {
         const purpleColor = '#836AF9',
            yellowColor = '#ffe800',
            cyanColor = '#28dac6',
            orangeColor = '#FF8132',
            orangeLightColor = '#FDAC34',
            oceanBlueColor = '#299AFF',
            greyColor = '#4F5D70',
            greyLightColor = '#EDF1F4',
            blueColor = '#2B9AFF',
            blueLightColor = '#84D0FF';

           // var ctx = document.getElementById("polarChart");
            const ctx = this.$refs.polarChart;
         
            this.myChart = new Chart(ctx, {
              type: 'polarArea',
              data: {
                labels: ['Africa', 'Asia', 'Europe', 'America', 'Antarctica', 'Australia'],
                datasets: [
                  {
                    label: 'Population (millions)',
                    backgroundColor: [purpleColor, yellowColor, orangeColor, oceanBlueColor, greyColor, cyanColor],
                    data: [19, 17.5, 15, 13.5, 11, 9],
                    borderWidth: 0
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 500
                },
                scales: {
                  r: {
                    ticks: {
                      display: false,
                      color: "#7983bb"
                    },
                    grid: {
                      display: false
                    }
                  }
                },
                plugins: {
                  tooltip: {
                    // Updated default tooltip UI
                    rtl: false,
                    backgroundColor: "#2f3349",
                    titleColor: "#cfd3ec",
                    bodyColor: "#b6bee3",
                    borderWidth: 1,
                    borderColor: "#434968"
                  },
                  legend: {
                    rtl: false,
                    position: 'right',
                    labels: {
                      usePointStyle: true,
                      padding: 25,
                      boxWidth: 8,
                      boxHeight: 8,
                      color: "#b6bee3"
                    }
                  }
                }
              }
            });
    },
    setup() {
    },
    computed:{
        
    },

    methods:{

        
    },

    watch:{
        
    },
}
</script>
