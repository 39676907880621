<style type="text/css">
    

</style>
<template>
     <div class="container-xxl">
      <div class="authentication-wrapper authentication-basic container-p-y">
        <div class="authentication-inner py-4">
          <!-- Reset Password -->
          <div class="card">
            <div class="card-body">
              <div class="app-brand justify-content-center mb-4 mt-2">
                  <a href="index.html" class="app-brand-link gap-2">
                    <span class="app-brand-logo demo">
                      <img src="/images/fleetworx.jpg" style="max-width:180px; max-height:180px;">
                    </span>
                  </a>
                </div>
              <h4 class="mb-1 pt-2">Reset Password 🔒</h4>
              <form id="formAuthentication" action="auth-login-basic.html" method="POST">
                <div class="mb-3 form-password-toggle">
                  <label class="form-label" for="password">New Password</label>
                  <div class="input-group input-group-merge">
                    <input
                      type="password"
                      id="password"
                      class="form-control"
                      name="password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                    />
                    <span class="input-group-text cursor-pointer"><i class="ti ti-eye-off"></i></span>
                  </div>
                </div>
                <div class="mb-3 form-password-toggle">
                  <label class="form-label" for="confirm-password">Confirm Password</label>
                  <div class="input-group input-group-merge">
                    <input
                      type="password"
                      id="confirm-password"
                      class="form-control"
                      name="confirm-password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                    />
                    <span class="input-group-text cursor-pointer"><i class="ti ti-eye-off"></i></span>
                  </div>
                </div>
                <button class="btn btn-primary d-grid w-100 mb-3">Set new password</button>
                <div class="text-center">
                  <router-link  class="d-flex align-items-center justify-content-center" to="/login"><i class="ti ti-chevron-left scaleX-n1-rtl"></i>Back to login</router-link>
                </div>
              </form>
            </div>
          </div>
          <!-- /Reset Password -->
        </div>
      </div>
    </div>
</template>

<script>

import '../../../../public/v3-assets/vendor/css/pages/page-auth.css';



export default {
    name: 'dashboard',
    components:{
    },
    props: [],
    data(){
        return{
           
        }   
    },

    created() {
    },
     setup() {
   
  },

    computed:{
        
    },

    methods:{

        
    },

    watch:{
        
    },
}
</script>
