import { v4 as uuidv4 } from "uuid";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
var unique_device_id= uuidv4();
export default {
  methods: {
    getCookie(cookieName) {
      let cookie = {};
      document.cookie.split(';').forEach(function(el) {
        let [key,value] = el.split('=');
        cookie[key.trim()] = value;
      })
      return cookie[cookieName];
    },
    async setDeviceInfo(config,vm) {
      var self = this;
      if (auth_user_type == 'patient') {
        await self.initializeFCM(config,vm);
      }
      self.callDeviceInfoEvent(config,vm);
    },
    async initializeFCM(config,vm){
      var self = this;
      try{
        // Initialize Firebase
        const firebaseConfig = {
          apiKey: CHAT_FCM_API_KEY,
          authDomain: CHAT_FCM_AUTH_DOMAIN,
          projectId: CHAT_FCM_PROJECT_ID,
          storageBucket: CHAT_FCM_STORAGEBUCKET,
          messagingSenderId: CHAT_FCM_MESSAGING_SENDER_ID,
          appId: CHAT_FCM_APP_ID,
          measurementId: CHAT_FCM_MEASUREMENT_ID
        };
        const fapp = initializeApp(firebaseConfig);
        const fmessaging = getMessaging();
        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            getToken(fmessaging, { vapidKey:  CHAT_FCM_VAP_ID_KEY}).then((currentToken) => {
              if (currentToken) {
                document.cookie = "firebaseResponseToken="+currentToken;
                setTimeout(function(){
                  self.callDeviceInfoEvent(config,vm);
                },200);
              }
            })
            .catch((err) => {
            });
            
            onMessage(fmessaging, (payload) => {
              if ((vm.$route.name != 'patient-chats' && vm.$route.name != 'provider-chats') &&
                payload.data.responseType == 2 || payload.data.responseType == 5) {
                toastr['info'](payload.notification.body, payload.notification.title, {
                  closeButton: true,
                  tapToDismiss: true,
                  timeOut: 5000,
                  rtl: false,
                });
              }
            })
          }
        });
      }catch(e){
      }
    },
    callDeviceInfoEvent(config,vm){
      var self = this;
      try{        
        var obj = {
          'device_platform': "web",
          'device_token': this.getCookie('firebaseResponseToken'),
          'device_unique_id': unique_device_id,
          'device_model': navigator.userAgent,
          'os': navigator.platform,   
          'userId': config.loggedin_user.id,            
          'app_version': config.version_txt,   
          'device_environment': config.CommonVariables.environment_status,
        }
        vm.$socket.emit("device-info", JSON.stringify(obj), function(emitdata) {
        });
      }catch(e){
      }
    },
    fileInputValidation(name,extensions){
      if (name) {
        const lowerCaseFileName = name.toLowerCase();
        extensions = String(extensions).toLowerCase().replace(/['"]/g, '');
        const extensionRegex = /(?:\.([^.]+))?$/;
        const extensionMatch = extensionRegex.exec(lowerCaseFileName);
        const fileExtension = extensionMatch ? extensionMatch[1] : null;
        if (fileExtension && extensions.toLowerCase().split('-').includes(fileExtension)) {
          return true; 
        }
      }
      return false; 
    },
    downloadOrViewFile(url, filename, action){
      let anchorElement = document.createElement('a');
      let file_name = filename;
      anchorElement.href = url;
      if(action == "download"){
        anchorElement.setAttribute('download', file_name);
      }
      else{
        anchorElement.target = "_blank";
      } 
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
    loadCustomers(){
      var self =this;
      self.$axios.get('get/customer/assigned/all',obj).then(function(response){
        return response.data.input_data;
      })
      .catch(function (error) {
      }); 
    },
    numberFromText(text) {
      const charCodes = text
        .split('') // => ["A", "A"]
        .map(char => char.charCodeAt(0)) // => [65, 65]
        .join(''); // => "6565"
        return parseInt(charCodes, 10);
    },
  },
  variables:{
      colors: ['#F44336', '#FF4081', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFC107', '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'],
  }
};